import React from 'react';
import T from 'prop-types';
import styled, { css } from 'styled-components';
import get from 'lodash.get';
import { Heading } from '@devseed-ui/typography';
import { glsp, media } from '@devseed-ui/theme-provider';

import UniversalGridder from '../styles/universal-gridder';

import { leading } from '../styles/typography/leading';
import { stripe } from '../styles/motifs';
import { SupHeading } from '../styles/typography/supheading';
import Prose from '../styles/typography/prose';
import Media from './media';

const renderDiptychSelfDecoration = ({ layout }) => {
  const decorationPosition = () => {
    switch (layout) {
      case 'alpha':
        return css`
          top: -1rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          grid-column-start: full-start;
          grid-column-end: content-3;

          ${media.smallUp`
            top: -2rem;
          `}

          ${media.mediumUp`
            grid-column-end: content-3;
            top: -3rem;
          `}

          ${media.largeUp`
            grid-column-end: content-4;
            top: -4rem;
          `}

          ${media.xlargeUp`
            top: -5rem;
          `}
        `;
      case 'beta':
        return css`
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          grid-column-start: content-4;
          grid-column-end: full-end;
          align-self: start;
          grid-row: 2;
          top: -2rem;

          ${media.mediumUp`
            top: 100%;
            grid-row: 1;
            grid-column-start: content-7;
            transform: translate(0, calc(-100% + 4rem));
          `}

          ${media.largeUp`
            grid-column-start: content-9;
            transform: translate(0, calc(-100% + 5rem));
          `}

          ${media.xlargeUp`
            transform: translate(0, calc(-100% + 6rem));
          `}
        `;
    }
  };

  return css`
    &::before {
      ${stripe};
      z-index: 10;
      grid-row: 1;

      ${media.smallUp`
        height: ${glsp(6)};
      `}

      ${media.mediumUp`
        height: ${glsp(8)};
      `}

      ${media.largeUp`
        height: ${glsp(10)};
      `}

      ${media.xlargeUp`
        height: ${glsp(12)};
      `}

      ${decorationPosition}
    }
  `;
};

const renderDiptychSelfLayout = ({ layout }) => {
  switch (layout) {
    case 'alpha':
      return css`
        ${DiptychCopy} {
          grid-row: 2;
          grid-column: content-start / content-end;

          ${media.mediumUp`
            grid-row: 1;
            grid-column: content-5 / content-end;
          `}

          ${media.largeUp`
            grid-column: content-7 / content-end;
          `}
        }

        video,
        ${DiptychMedia} {
          grid-row: 1;
          grid-column: content-start / content-end;

          ${media.mediumUp`
            grid-column: content-start / span 4;
          `}

          ${media.largeUp`
            grid-column: content-start / span 5;
          `}
        }
      `;
    case 'beta':
      return css`
        ${DiptychCopy} {
          grid-row: 1;
          grid-column: content-start / content-end;

          ${media.mediumUp`
            grid-column: content-start / span 4;
          `}

          ${media.largeUp`
            grid-column: content-start / span 6;
          `}
        }

        video,
        ${DiptychMedia} {
          grid-row: 2;
          grid-column: content-start / content-end;

          ${media.mediumUp`
            grid-row: 1;
            grid-column: content-5 / content-end;
          `}

          ${media.largeUp`
            grid-column: content-8 / content-end;
          `}
        }
      `;
    default:
      throw 'A layout property is needed for the Diptych';
  }
};

export const DiptychSelf = styled(UniversalGridder).attrs({ as: 'section' })`
  grid-row-gap: ${glsp(1)};
  position: relative;

  ${media.smallUp`
    grid-row-gap: ${glsp(1)};
  `}

  ${media.mediumUp`
    grid-row-gap: ${glsp(2)};
  `}

  ${media.largeUp`
    grid-row-gap: ${glsp(3)};
  `}

  ${media.xlargeUp`
    grid-row-gap: ${glsp(4)};
  `}

  ${({ noDecoration }) => !noDecoration && renderDiptychSelfDecoration}


  ${renderDiptychSelfLayout}
`;

export const DiptychCopy = styled.div`
  align-self: center;
`;

export const DiptychTitle = styled(Heading).attrs({ as: 'h1' })`
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin: ${glsp(0, 0, 1, 0)};

  ${media.smallUp`
    font-size: 2rem;
    line-height: 2.5rem;
  `}

  ${media.mediumUp`
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: ${glsp(2)};
  `}

  ${media.largeUp`
    font-size: 2.75rem;
    line-height: 3.25rem;
  `}

  ${media.xlargeUp`
    font-size: 3rem;
    line-height: 3.5rem;
  `}
`;

const DiptychSuptitle = styled(SupHeading)`
  margin: ${glsp(0, 0, 2, 0)};
`;

export const DiptychProse = styled(Prose)`
  ${leading()}
`;

export const DiptychMedia = styled(Media)`
  height: 100%;

  .gatsby-image-wrapper {
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const Diptych = (props) => {
  const {
    layout,
    title,
    suptitle,
    children,
    dangerouslySetInnerHTML,
    fluid,
    src,
    alt,
    className,
    noDecoration,
    ...rest
  } = props;

  const html = get(dangerouslySetInnerHTML, '__html');
  // Use html if provided, otherwise default to children.
  // HTML is needed for the rendered markdown content.
  const proseProps = html
    ? {
        dangerouslySetInnerHTML
      }
    : { children };
  return (
    <DiptychSelf
      {...rest}
      className={className}
      layout={layout}
      noDecoration={noDecoration}
    >
      <DiptychCopy>
        {suptitle && (
          <DiptychSuptitle variation='primary' as='h2'>
            {suptitle}
          </DiptychSuptitle>
        )}
        <DiptychTitle>{title}</DiptychTitle>
        <DiptychProse {...proseProps} />
      </DiptychCopy>
      {src?.endsWith('.mp4') ? (
        <video width='100%' controls>
          <source src={src} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      ) : (
        <DiptychMedia decoration='none' src={src} fluid={fluid} alt={alt} />
      )}
    </DiptychSelf>
  );
};

Diptych.propTypes = {
  layout: T.oneOf(['alpha', 'beta']),
  className: T.string,
  title: T.node,
  suptitle: T.node,
  src: T.string,
  fluid: T.object,
  alt: T.string,
  noDecoration: T.bool,
  children: T.node,
  dangerouslySetInnerHTML: T.object
};

export default Diptych;
