import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import get from 'lodash.get';
import { glsp, media } from '@devseed-ui/theme-provider';
import { Button } from '@devseed-ui/button';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageBody,
  InpageLead
} from '../../styles/inpage';
import Diptych from '../../components/diptych';
import {
  CTAActions,
  CTASectionHeadline,
  CTASectionLead
} from '../../components/call-to-action';

import Media from '../../components/media';
import { Section, SectionHeader, SectionTitle } from '../../styles/section';

import ctaImg from '../../media/layout/how-we-work--cover.jpg';

const ExpertiseTitleLink = styled(Link)`
  &,
  &:visited {
    color: inherit;
  }
`;

const CtaSection = styled(Section)`
  margin-top: ${glsp(3)};

  ${media.largeUp`
    margin-top: ${glsp(6)};
  `}
`;

const ExpertiseCTAMedia = styled(Media)`
  grid-column: content-start / content-end;
`;

const Expertise = ({ data, location }) => {
  const expertise = data.allExpertise.nodes;

  return (
    <Layout location={location} title='What We Do'>
      <Inpage as='section'>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>What We Do</InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                Our partners use our tools to make better decisions, smarter
                investments, and plan a better future. Our tools deliver
                streaming insights on the planet and populations, delivered
                when, where, and how decisions are made.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody>
          {expertise.map((exp, i) => {
            const { id, slug } = exp;
            const { title, media, lead } = exp.frontmatter;
            const cardImage = get(media, 'card.url.childImageSharp.fluid');

            return (
              <Diptych
                key={id}
                layout={i % 2 ? 'alpha' : 'beta'}
                title={
                  <ExpertiseTitleLink to={`/expertise/${slug}`}>
                    {title}
                  </ExpertiseTitleLink>
                }
                fluid={cardImage}
                alt='Expertise image'
              >
                <p>{lead}</p>
              </Diptych>
            );
          })}

          <CtaSection>
            <SectionHeader>
              <ExpertiseCTAMedia
                decoration='none'
                src={ctaImg}
                alt='People meeting in a room'
              />
              <CTASectionHeadline>
                <SectionTitle>Our approach to working together</SectionTitle>
              </CTASectionHeadline>
              <CTASectionLead>
                <p>
                  Together, let&apos;s tap into geospatial data&apos;s potential
                  and create solutions that matter.
                </p>
                <CTAActions>
                  <Button
                    variation='primary-fill'
                    size='large'
                    forwardedAs={Link}
                    to='/how-we-work'
                  >
                    Learn how we work
                  </Button>
                </CTAActions>
              </CTASectionLead>
            </SectionHeader>
          </CtaSection>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Expertise;

Expertise.propTypes = {
  data: T.object,
  location: T.object
};

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// TeamGroups - Auxiliary components                                          //
//                                                                            //

//                                                                            //
// END   TeamGroups - Auxiliary components                              //
// // // // // // // // // // // // // // // // // // // // // // // // // // //

export const pageQuery = graphql`
  query {
    allExpertise(
      filter: { published: { eq: true } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        ...ExpertiseCardData
      }
    }
  }
`;
