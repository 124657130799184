import styled, { css } from 'styled-components';
import { tint } from 'polished';

import {
  themeVal,
  glsp,
  media,
  stylizeFunction,
  add
} from '@devseed-ui/theme-provider';
import { createTableStyles } from '@devseed-ui/typography';

const _tint = stylizeFunction(tint);

const makeHeadingSize = (base) => css`
  font-size: ${base};

  ${media.mediumUp`
    font-size: ${add(base, '0.5rem')};
  `}

  ${media.largeUp`
    font-size: ${add(base, '0.75rem')};
  `}

  ${media.xlargeUp`
    font-size: ${add(base, '1rem')};
  `}
`;

const Prose = styled.div`
  font-size: 1rem;
  line-height: 1.5;

  ${media.mediumUp`
    font-size: 1.25rem;
    line-height: 1.6;
  `}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${themeVal('type.heading.family')};
    text-transform: ${themeVal('type.heading.case')};
    font-weight: ${themeVal('type.heading.weight')};
    font-style: ${themeVal('type.heading.style')};
    font-variation-settings: ${themeVal('type.heading.settings')};
    line-height: calc(1em + 0.5rem);
  }

  h1 {
    ${makeHeadingSize('2.5rem')}
  }

  h2 {
    ${makeHeadingSize('2.25rem')}
  }

  h3 {
    ${makeHeadingSize('2rem')}
  }

  h4 {
    ${makeHeadingSize('1.75rem')}
  }

  h5 {
    ${makeHeadingSize('1.5rem')}
  }

  h6 {
    ${makeHeadingSize('1.25rem')}
  }

  ol,
  dl {
    padding: 0;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: ${themeVal('layout.space')};
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  dl {
    > *::last-child {
      margin-bottom: 0;
    }
  }

  dt {
    font-family: ${themeVal('type.heading.family')};
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: normal;
    font-style: normal;
    font-variation-settings: 'wdth' 32, 'wght' 608;
  }

  dd {
    margin-bottom: 0.5rem;
  }

  figure > figcaption {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    color: ${_tint(0.32, themeVal('color.base'))};
    padding: ${glsp(1, themeVal('layout.gap.xsmall'))};
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;

    ${media.smallUp`
      padding: ${glsp(1, themeVal('layout.gap.small'))};
    `}

    ${media.mediumUp`
      font-size: 1rem;
      line-height: 1.5rem;
      padding: ${glsp(2, themeVal('layout.gap.medium'))};
    `}

    ${media.largeUp`
      padding: ${glsp(2, themeVal('layout.gap.large'))};
    `}

    ${media.xlargeUp`
      padding: ${glsp(2, themeVal('layout.gap.xlarge'))};
    `}
  }

  figure img {
    display: block;
    margin: 0 auto;
  }

  video,
  img {
    max-width: 100%;
  }

  table {
    ${createTableStyles()};
  }

  pre {
    border: 1px solid ${themeVal('color.base-200a')};
    padding: ${glsp()};

    code {
      display: block;
      overflow-x: auto;
    }
  }

  > * {
    margin-bottom: ${glsp(1.5)};

    ${media.mediumUp`
      margin-bottom: ${glsp(2)};
    `}
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export default Prose;
