import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { media, glsp } from '@devseed-ui/theme-provider';
import {
  Section,
  SectionHeadline,
  SectionTitle,
  SectionHeader,
  SectionSuptitle,
  SectionLead
} from '../styles/section';

export const CTASectionHeadline = styled(SectionHeadline)`
  ${media.largeUp`
    grid-column: content-3 / content-9;
  `}
`;

export const CTASectionLead = styled(SectionLead)`
  grid-column: content-start / content-end;

  && {
    grid-row: auto;
  }

  ${media.largeUp`
    grid-column: content-3 / content-9 !important;
  `}
`;

export const CTAActions = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${glsp()};

  ${media.largeUp`
    gap: ${glsp(2)};
  `}
`;

export function CallToAction(props) {
  const { title, suptitle, children } = props;
  return (
    <Section>
      <SectionHeader>
        <CTASectionHeadline>
          <SectionTitle>{title}</SectionTitle>
          {suptitle && <SectionSuptitle>{suptitle}</SectionSuptitle>}
        </CTASectionHeadline>
        <CTASectionLead>{children}</CTASectionLead>
      </SectionHeader>
    </Section>
  );
}

CallToAction.propTypes = {
  title: T.node,
  suptitle: T.node,
  children: T.node
};
